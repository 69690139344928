@use './mixin' as *;
@use './vars' as *;

.Toastify__toast {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px !important;
  font-weight: normal;
  font-family: $font-primary;
  backdrop-filter: blur(5px);
  padding: 8px 16px;
  color: #fff !important;

  &-body {
    padding: 0 !important;
  }

  & svg {
    fill: #fff !important;
  }

  & div[role='progressbar'] {
    background-color: #fff !important;
  }
}

.Toastify__close-button--light {
  opacity: 1;
  color: #fff;
}

.Toastify__toast--success {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 1px rgba(255, 255, 255, 0.5);
  background-color: #2c873f !important;
}

.Toastify__toast--info {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 1px rgba(255, 255, 255, 0.5);
  background-color: #1a74dc !important;
}

.Toastify__toast--warning {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 1px rgba(255, 255, 255, 0.5);
  background-color: #c24e0a !important;
}

.Toastify__toast--error {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0.5px 1px rgba(255, 255, 255, 0.5);
  background-color: #e00016 !important;
}
