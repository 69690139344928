@use 'src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-tool-tip {
  transition: 0.2s;
  color: #a8a8a8;
  border: 1px solid #a8a8a8;
  border-radius: 50%;
  padding: 1px;
}
