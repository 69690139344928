@use '~src/scss/vars' as *;

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #22767c;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 1em;
  --PhoneInputCountrySelectArrow-width: 0.35em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight) / 2;
  --PhoneInputCountrySelectArrow-marginTop: calc(var(--PhoneInputCountrySelectArrow-height) / 2);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1.2em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: #d6d6d6;
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: #fff;
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
  border-radius: 0;
  width: 100%;
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-right: var(--PhoneInputCountrySelect-marginRight);
  border: 1px solid #d6d6d6;
  background-color: #fff;
  border-radius: $input-boder-radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  transition: $default-transition;
}

.PhoneInputCountry[disabled] {
  background-color: #d8d8d8;
}
.PhoneInputCountrySelect[disabled] {
  cursor: not-allowed;
}

.PhoneInputInput[disabled] {
  color: #9f9f9f;
  cursor: not-allowed;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
  // background-color: green;
  height: 40px;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: $input-boder-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 12px;
  transition: $default-transition;
}

.PhoneInput--focus {
  .PhoneInputCountry,
  .PhoneInputInput {
    // box-shadow: $focus-shadow;
    border: $default-border-primary;
  }
  .PhoneInputInput {
    border-left: none;
  }
}

.PhoneInputCountryIcon {
  background-color: #fff;
  width: calc(
    var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio) +
      var(--PhoneInputCountrySelect-marginRight) * 2
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  // background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  // box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
  //     var(--PhoneInputCountryFlag-borderColor),
  //   inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
  //     var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelectArrow {
  display: block;
  width: 0.4em;
  height: 0.4em;
  margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: #58585b;
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: 1;
}

.PhoneInputCountrySelect:focus {
  border-color: #22767c;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  // box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
  //     var(--PhoneInputCountryFlag-borderColor--focus),
  //   inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
  //     var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
