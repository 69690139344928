@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.ctn-uam {
  background-color: $color-white;
  min-height: calc(100vh - #{$navbar-height});
  margin: 32px auto;
  padding: 0 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;

  @include mobileScreen {
    margin: 0px;
    border-width: 0px;
    border-radius: 0;
    padding: 0;
  }

  & {
    &__container--wrap {
      width: 100%;
      height: 100%;
    }

    &__container {
      width: 560px;
      align-self: center;
      text-align: left;
      padding: 24px 0px;
      transition: $default-transition-med;

      @include desktopScreen {
        margin: auto;
        // text-align: center;
      }

      @include mobileScreen {
        width: 100%;
        padding: 60px 16px 40px;
      }
    }

    &__image {
      position: absolute;
      left: 0;
      top: 0;

      width: 50vw;
      height: 100%;
      object-fit: cover;
      object-position: left;
      transition: $default-transition-med;

      @include desktopScreen {
        display: none;
      }
    }

    &__logo {
      // width: 85px;
      height: 3rem;
      align-self: flex-start;
    }

    &__title {
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__link {
      font-size: 14px;
      // margin-left: auto;
    }

    &__verify-code {
      justify-content: flex-start;
    }

    &__terms-link {
      color: $color-link !important;
      font-size: 16px !important;
    }
  }
}

.muibackdrop-root {
  z-index: 999 !important;
  width: 100vw !important;
  color: #fff;
}
