@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-count-table {
  width: 100%;
  & th {
    border: $default-border;
    font-size: 12px;
    font-weight: 500;
    color: $color-black-500;
    padding: 10px;
    background-color: $color-white;
  }
  & td {
    border: $default-border;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    background-color: $color-white;
  }
  text-align: center;
  .cmp-count-table {
    &__header {
      &--light {
        font-weight: 400;
        color: $color-black-300;
      }
    }
    &__input {
      width: 100%;
      border: none;
      box-shadow: none;
      text-align: right;
      color: $color-black-500;
      height: 40px;
      &::placeholder {
        font-size: 14px;
      }
      &--dark {
        background: #f7f8fa;
      }
    }
  }
}
