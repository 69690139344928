@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-button-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px;
  margin-top: 4px;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + &__slider {
    background-color: #b0c5ff;
  }
  input:focus + &__slider {
    box-shadow: 0 0 1px $color-primary;
  }
  input:checked + &__slider::before {
    transform: translateX(22px);
    background-color: $color-primary;
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9e9e9e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  &__slider::before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: -4px;
    bottom: -3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(140, 140, 140, 0.37);
  }
}
